<template>
	<header class="">
		<div class="content-wrapper flex items-center py-4">
			<div class="logo">
				<nuxt-link to="/">
					<i class="ico ico-mcf-logo"></i>
				</nuxt-link>
			</div>
			<div class="dates px-2 mx-2 ml-5 de:px-5 de:mx-5">
				<p class="font-hairline">
					Feb 26 <br />
					<span class="text-small">&mdash;</span> Mar 1
				</p>
				<p class="font-extrabold text-biggest">2020</p>
			</div>
			<div class="menu relative ml-auto">
				<a
					class="ico ico-mcf-hamburger cursor-pointer py-5 text-larger"
					@click="showNav = !showNav"
					v-if="!showNav"
				></a>
				<a
					class="ico ico-mcf-close cursor-pointer py-3 text-larger"
					@click="showNav = !showNav"
					v-if="showNav"
				></a>
				<nav
					class="bg-dark bg-green-dark w-screen de:w-auto de:absolute"
					v-if="showNav"
					@click="showNav = !showNav"
				>
					<ul>
						<li>
							<nuxt-link to="/"
								><span>Home</span><span>></span></nuxt-link
							>
						</li>
						<li>
							<nuxt-link to="/bar-safari"
								><span>Bar Safari</span
								><span>></span></nuxt-link
							>
						</li>
						<li>
							<nuxt-link to="/events">
								<span>Events</span><span>></span>
							</nuxt-link>
						</li>
						<li>
							<nuxt-link to="/symposium"
								><span>Symposium</span><span>></span></nuxt-link
							>
						</li>
						<li>
							<nuxt-link to="/about"
								><span>About</span><span>></span></nuxt-link
							>
						</li>
						<li>
							<a target="_blank" href="https://worksmith.io"
								><span>Worksmith</span><span>></span></a
							>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	data: () => {
		return {
			showNav: false
		}
	}
}
</script>

<style lang="scss" scoped>
@import 'Header';
</style>
