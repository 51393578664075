export const state = () => ({
	showNewsletter: false,
	notifications: []
})

export const mutations = {
	setNewsletter(state, status) {
		state.showNewsletter = status
	},
	setNotification(state, notification) {
		state.notifications.push(notification)
	},

	removeNotification(state, message) {
		const newN = state.notifications.filter(n => n.text !== message)
		state.notifications = newN
	}
}

export const actions = {
	toggleNewsletter({ commit, state }) {
		commit('setNewsletter', !state.showNewsletter)
	},

	addNotification({ commit }, notification) {
		commit('setNotification', notification)
	},

	removeNotification({ commit }, message) {
		commit('removeNotification', message)
	}
}

export const getters = {
	showNewsletter(state) {
		return state.showNewsletter
	},
	notifications(state) {
		return state.notifications
	}
}
