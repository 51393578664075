<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="h-full">
		<Notifications></Notifications>
		<Header></Header>
		<div class="content">
			<nuxt />
		</div>
		<Footer></Footer>
		<Newsletter></Newsletter>
	</div>
</template>

<script>
import Footer from '~/components/Footer.vue'
import Header from '~/components/Header.vue'
import { mapGetters } from 'vuex'
import Newsletter from '~/components/Newsletter.vue'
import Notifications from '~/components/Notifications.vue'

export default {
	...mapGetters(['showNewsletter']),
	components: {
		Header,
		Footer,
		Newsletter,
		Notifications
	},
	data: () => ({})
}
</script>

<style lang="scss">
@import './default';
</style>
