<template>
	<div class="newsletter" v-if="showNewsletter">
		<a
			@click="$store.dispatch('toggleNewsletter')"
			class="ico ico-close light"
		></a>
		<h3 class="mb-3 de:mb-5 font-bold">Sign Up</h3>
		<h5>
			Be the first to know when we add new events and when ticket sales
			launch!
		</h5>
		<form
			action="/"
			@submit.prevent="submitForm"
			method="POST"
			name="newsletter-signup"
			data-netlify-honeypot="dontfill"
			data-netlify="true"
			class="flex flex-col"
		>
			<input type="hidden" name="form-name" value="newsletter-signup" />
			<input
				class="w-full"
				type="textbox"
				name="email"
				placeholder="Enter your email address"
				v-model="form.email"
			/>
			<span class="hidden"><input name="dontfill"/></span>
			<input
				class="btn de:btn--large self-center de:self-end cursor-pointer"
				type="submit"
				id="submitFormButton"
				name="submit"
				value="Submit"
				v-track:click="{
					category: 'newsletter',
					label: 'submit'
				}"
			/>
		</form>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
	computed: {
		...mapGetters(['showNewsletter'])
	},
	data: () => {
		return {
			form: {
				email: ''
			}
		}
	},
	methods: {
		encode(data) {
			return Object.keys(data)
				.map(
					key =>
						`${encodeURIComponent(key)}=${encodeURIComponent(
							data[key]
						)}`
				)
				.join('&')
		},
		submitForm() {
			if (!this.form.email) {
				this.$store.dispatch('addNotification', {
					type: 'error',
					text:
						"We'll need your email address to sign you up to the list."
				})

				this.$ga.event({
					eventCategory: 'newsletter',
					eventLabel: 'missing email',
					eventAction: 'error'
				})
				return false
			}

			const axiosConfig = {
				header: { 'Content-Type': 'application/x-www-form-urlencoded' }
			}

			axios
				.post(
					'/',
					this.encode({
						'form-name': 'newsletter-signup',
						...this.form
					}),
					axiosConfig
				)
				.then(() => {
					this.$store.dispatch('addNotification', {
						type: 'success',
						text:
							"We've successfully added you to the mailing list."
					})

					this.$ga.event({
						eventCategory: 'newsletter',
						eventLabel: 'submit',
						eventAction: 'success'
					})

					this.form.email = ''
				})
				.catch(() => {
					this.$store.dispatch('addNotification', {
						type: 'error',
						text:
							"There was an error submitting the form.  Please send us an email and we'll fix it as quickly as possible."
					})

					this.$ga.event({
						eventCategory: 'newsletter',
						eventLabel: 'submit',
						eventAction: 'error'
					})
				})
		}
	}
}
</script>

<style lang="scss" scoped>
@import 'Newsletter.scss';
</style>
