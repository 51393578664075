<template>
	<footer class="bg-dark">
		<div class="py-10 content-wrapper flex justify-between flex-wrap">
			<div class="social flex">
				<a
					class="block mb-2 mr-5"
					href="https://www.instagram.com/melbournecocktailfestival/"
					target="_blank"
					><i class="ico ico-instagram text-bigger"></i
				></a>
				<a
					class="block"
					href="https://www.facebook.com/melbournecocktailfestival"
					target="_blank"
					><i class="ico ico-facebook text-bigger"></i
				></a>
			</div>
			<!-- <nav>
				<ul>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
				</ul>
			</nav>
			<nav>
				<ul>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
				</ul>
			</nav>
			<nav>
				<ul>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
					<li><a>Link 1</a></li>
				</ul>
			</nav> -->
			<p
				class="acknowledgement max-w-sm text-smaller font-normal my-10 py-10 border-cream border border-l-0 border-r-0 de:my-0 de:border-0 de:py-0 "
			>
				Worksmith acknowledges the Traditional Owners of the land on
				which we operate, the Wurundjeri people of the Kulin Nation, and
				recognise their continuing connection to land, waters and
				culture. We pay our respects to their Elders past, present and
				emerging. Sovereignty was never ceded.
			</p>
			<div class="ws">
				<p class="text-smaller font-hairline">Brought to life by</p>
				<a
					target="_blank"
					href="https://worksmith.io"
					class="mt-3 text-large"
					><i class="ico ico-logo"></i>
					<span class="font-hairline">></span>
				</a>
			</div>
		</div>
		<div class="border-t mt-5 py-5 text-smaller font-normal">
			<div class="content-wrapper flex flex-col de:flex-row items-center">
				<span>
					<a
						class="btn"
						href="mailto:drink@melbournecocktailfestival.io"
						>Enquiries: drink@melbournecocktailfestival.io
					</a>
				</span>
				<div class="de:ml-auto mt-2 de:mt-0">
					<span>&copy; Worksmith.</span>
					<span class="ml-4">Grow. Hospitality. Together</span>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import 'Footer';
</style>
